import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import { NextPageWithLayout } from 'pages/_app';
import { useMemo } from 'react';

const Login = dynamic(() => import('domains/authentication/sign-in'));

type TAuthPath = 'login' | 'register' | 'forgot-password';
const authRoutes: TAuthPath[] = ['login'];

const Auth: NextPageWithLayout<InferGetStaticPropsType<typeof getStaticProps>> = ({ page }) => {
  const AuthChild = useMemo(() => {
    switch (page) {
      default:
        return Login;
    }
  }, [page]);

  return <AuthChild />;
};

Auth.useLayout = 'auth';

export default Auth;

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = authRoutes.map((route) => ({
    params: { stage: route }
  }));

  return { paths, fallback: false };
};

export const getStaticProps: GetStaticProps<{ page: TAuthPath }, { stage: TAuthPath }> = ({
  params
}) => ({ props: { page: params?.stage || 'login' } });
