
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/auth/[stage]",
      function () {
        return require("private-next-pages/auth/[stage].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/auth/[stage]"])
      });
    }
  